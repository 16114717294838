import { useEffect, useMemo, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import {
    Box,
    CircularProgress,
    Divider,
    Grid,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import { format, isAfter, isBefore } from "date-fns";
import ro from "date-fns/locale/ro";
import YoutubePlayer from "./common/YoutubePlayer";
import ChatWidget from "./common/ChatWidget";
import {
    getNextEvents,
    getEventSchedule,
    joinEvent,
} from "./core/services/firebase/firebase";
import Countdown from "react-countdown";
import { LoadingButton } from "@mui/lab";
import SocialTags from "./common/SocialTags";
import ChatBanner from "./common/ChatBanner";

const Logo = styled(Box)(({ theme }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "#2f80ed",
    backgroundImage: "url(/logo.png)",
    backgroundSize: "80%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    width: 60,
    height: 60,
    borderBottomRightRadius: 24,
    zIndex: 1000,
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

const PageContainer = styled(Box)(({ theme }) => ({
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
}));

const EventRegister = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
}));

const EventDesktop = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        marginRight: 400,
    },
}));

const EventVideoBadge = styled(Box)(({ theme }) => ({
    display: "none",
    [theme.breakpoints.up("md")]: {
        display: "block",
        backgroundColor: "#eb5757",
        width: 180,
        paddingLeft: 10,
        position: "relative",
        color: "#fff",
        fontWeight: "bold",
        textTransform: "uppercase",
        textAlign: "left",

        "&:before": {
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            borderTop: "80px solid #fff",
            borderLeft: "80px solid #eb5757",
            width: 0,
        },
    },
}));

const EventVideo = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        maxWidth: 1060,
        margin: "0 auto",
        marginTop: theme.spacing(10),
    },
}));

const EventVideoPlayer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        backgroundColor: "#000",
        padding: theme.spacing(1),
        position: "relative",
        zIndex: 1,
    },
}));

const EventChat = styled(Box)(({ theme }) => ({
    flex: 1,
    minHeight: 480,
    height: "100%",
    [theme.breakpoints.up("md")]: {
        position: "fixed",
        right: 0,
        top: 0,
        height: "100%",
        width: 400,
        boxShadow: "0 1px 10px rgba(0, 0, 0, 0.1)",
        padding: 0,
    },
}));

const CountdownBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    margin: theme.spacing(2, 0),
}));

const CountdownBoxItem = styled(Box)(({ theme }) => ({
    margin: theme.spacing(0, 2),
    "& > span": {
        display: "block",
        width: 50,

        "&:first-of-type": {
            fontSize: 36,
            fontWeight: "bold",
        },
    },
}));

function Event() {
    const loadedUser = JSON.parse(window.localStorage.getItem("rx_live_user"));

    const [list, setList] = useState([]);
    const [init, setInit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [schedule, setSchedule] = useState([]);
    const [user, setUser] = useState(loadedUser);
    const [form, setForm] = useState({
        name: "",
        email: "",
    });
    const [params] = useSearchParams();

    const target = params.get("target") || "desktop";
    // const os = params.get("os");
    const name = params.get("name");
    const loadedEventId = params.get("eventId") || "";

    useEffect(() => {
        getNextEvents().then((events) => {
            setList(events);

            if (events.length < 1) {
                setInit(true);
                return;
            }

            getEventSchedule(events[0].id).then((schedule) => {
                setSchedule(schedule);
                setInit(true);
            });
        });
    }, []);

    const event = useMemo(() => {
        if (list.length === 0) return null;

        return list[0];
    }, [list]);

    console.log(event);

    const nextEvent = useMemo(() => {
        if (schedule.length === 0) return null;

        // sort by start date
        schedule.sort((a, b) => {
            const aDate = a.startDate.toDate();
            const bDate = b.startDate.toDate();

            if (aDate < bDate) {
                return -1;
            }
            if (aDate > bDate) {
                return 1;
            }
            return 0;
        });

        const now = new Date();
        const nextEvent = schedule.find((event) => {
            const endDate = event.endDate.toDate();

            return endDate > now;
        });

        return nextEvent;
    }, [schedule]);

    const currentEvent = useMemo(() => {
        if (schedule.length === 0) {
            if (list.length > 0) {
                return list[0];
            }
            return null;
        }

        if (loadedEventId) {
            const exists = schedule.find((itm) => itm.id === loadedEventId);
            return exists || nextEvent;
        }

        return nextEvent;
    }, [list, schedule, loadedEventId, nextEvent]);

    const needsRegister = useMemo(() => {
        if (event && !user) return true;
        if (event && user && user.eventId !== event.id) return true;

        return false;
    }, [user, event]);

    const liveEnded = useMemo(() => {
        if (!currentEvent) return false;

        const endDate = currentEvent.liveEndDate || currentEvent.endDate;

        return isAfter(new Date(), endDate.toDate());
    }, [currentEvent]);

    const liveNow = useMemo(() => {
        if (!currentEvent) return false;

        const startDate = currentEvent.liveStartDate || currentEvent.startDate;
        const endDate = currentEvent.liveEndDate || currentEvent.endDate;

        return (
            isBefore(new Date(), endDate.toDate()) &&
            isAfter(new Date(), startDate.toDate())
        );
    }, [currentEvent]);

    const videoLabel = useMemo(() => {
        if (liveNow) {
            return "Live";
        }

        if (!liveEnded) {
            return "Urmează";
        }

        return "Reluare";
    }, [liveNow, liveEnded]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setForm((old) => ({
            ...old,
            [name]: value,
        }));
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        if (!form.email && !form.name) return;

        const data = {
            email: form.email,
            name: form.name,
            eventId: event.id,
            createdAt: new Date().toISOString(),
        };

        setLoading(true);

        await joinEvent(data);
        await fetch(
            "https://hook.eu1.make.com/3da6eztyl43s23q3hcbbhbb5by9vww5u",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: form.email,
                    name: form.name,
                    listId: event.listId,
                }),
            }
        );

        setUser(data);
        window.localStorage.removeItem("chat_id");
        window.localStorage.removeItem("chat_name");
        window.localStorage.setItem("rx_live_user", JSON.stringify(data));

        setLoading(false);
    };

    const countdownRenderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }) => {
        if (completed) {
            // Render a completed state
            return null;
        } else {
            // Render a countdown
            return (
                <CountdownBox>
                    <CountdownBoxItem>
                        <span>{days}</span>
                        <span>{days === 1 ? "zi" : "zile"}</span>
                    </CountdownBoxItem>
                    <CountdownBoxItem>
                        <span>{hours}</span>
                        <span>{hours === 1 ? "oră" : "ore"}</span>
                    </CountdownBoxItem>
                    <CountdownBoxItem>
                        <span>{minutes}</span>
                        <span>{minutes === 1 ? "minut" : "minute"}</span>
                    </CountdownBoxItem>
                    <CountdownBoxItem>
                        <span>{seconds}</span>
                        <span>{seconds === 1 ? "secundă" : "secunde"}</span>
                    </CountdownBoxItem>
                </CountdownBox>
            );
        }
    };

    if (!event && !init) {
        return (
            <PageContainer>
                {target === "desktop" && <Logo />}

                <EventRegister>
                    <Box>
                        <CircularProgress />
                    </Box>
                </EventRegister>
            </PageContainer>
        );
    }

    if (!event && init) {
        return (
            <PageContainer>
                {target === "desktop" && <Logo />}

                <EventRegister>
                    <Box sx={{ maxWidth: 380, p: 4 }}>
                        <Typography variant="h6">
                            Evenimentul este anulat
                        </Typography>
                        <Typography>
                            Mai multe detalii despre anularea evenimentul
                            regăsiți transmis pe emailul cu care v-ați
                            înregistrat.
                        </Typography>
                    </Box>
                </EventRegister>
            </PageContainer>
        );
    }

    if (target === "desktop" && needsRegister) {
        return (
            <PageContainer>
                <SocialTags {...event} />

                <Logo />

                <EventRegister>
                    <Box>
                        <Box sx={{ maxWidth: 500, margin: "0 auto" }}>
                            <img src={event.cover} alt={event.title} />
                        </Box>
                        <Box sx={{ my: 4 }}>
                            {liveEnded ? (
                                <Typography>
                                    Evenimentul a luat sfârșit
                                </Typography>
                            ) : liveNow ? (
                                <Typography>LIVE Acum</Typography>
                            ) : (
                                <>
                                    <Typography>
                                        Următorul eveniment live în
                                    </Typography>
                                    <Countdown
                                        date={event.liveStartDate.toDate()}
                                        renderer={countdownRenderer}
                                    />
                                </>
                            )}

                            <Typography variant="h6">{event.title}</Typography>
                            {schedule && schedule.length > 0 ? (
                                <Typography variant="h6">
                                    {format(
                                        event.liveStartDate.toDate(),
                                        "dd MMM",
                                        {
                                            locale: ro,
                                        }
                                    )}{" "}
                                    -{" "}
                                    {format(
                                        event.liveEndDate.toDate(),
                                        "dd MMM yyyy",
                                        {
                                            locale: ro,
                                        }
                                    )}
                                    , Ora{" "}
                                    {format(
                                        event.liveStartDate.toDate(),
                                        "HH:mm",
                                        {
                                            locale: ro,
                                        }
                                    )}
                                </Typography>
                            ) : (
                                <Typography variant="h6">
                                    {format(
                                        event.liveStartDate.toDate(),
                                        "dd MMM yyy",
                                        {
                                            locale: ro,
                                        }
                                    )}
                                    , Ora{" "}
                                    {format(
                                        event.liveStartDate.toDate(),
                                        "HH:mm",
                                        {
                                            locale: ro,
                                        }
                                    )}
                                </Typography>
                            )}
                        </Box>

                        <Box sx={{ maxWidth: 300, margin: "0 auto" }}>
                            <form onSubmit={handleRegister}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Numele tău"
                                            name="name"
                                            value={form.name}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Emailul tău"
                                            name="email"
                                            value={form.email}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            type="submit"
                                            disabled={loading}
                                            loading={loading}
                                        >
                                            {liveEnded
                                                ? "Vreau să văd reluarea"
                                                : "Vreau să particip"}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </Box>
                </EventRegister>
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            {target !== "mobile" && <Logo />}

            <EventDesktop>
                <EventVideo>
                    <EventVideoBadge>{videoLabel}</EventVideoBadge>
                    <EventVideoPlayer>
                        {currentEvent.youtubeId && (
                            <YoutubePlayer id={currentEvent.youtubeId} />
                        )}
                    </EventVideoPlayer>

                    <ChatBanner event={event} currentEvent={currentEvent} />

                    {schedule.length > 0 && target === "desktop" && (
                        <Box sx={{ my: 4 }}>
                            <Typography variant="h6">
                                Programul zilnic
                            </Typography>

                            {schedule.map((item) => (
                                <div key={item.id}>
                                    {item.endDate.toDate() < new Date() ? (
                                        <Link to={`/?eventId=${item.id}`}>
                                            <Typography variant="h6">
                                                {item.title}
                                            </Typography>
                                        </Link>
                                    ) : item.id === nextEvent.id ? (
                                        <Link
                                            to={`/`}
                                            style={{ color: "#eb5757" }}
                                        >
                                            <Typography variant="h6">
                                                {item.title}
                                            </Typography>
                                        </Link>
                                    ) : (
                                        <Typography variant="h6">
                                            {item.title}
                                        </Typography>
                                    )}
                                </div>
                            ))}
                        </Box>
                    )}
                </EventVideo>
            </EventDesktop>

            <EventChat>
                <ChatWidget
                    name={target === "desktop" ? user.name : name}
                    event={event}
                />
            </EventChat>

            {target !== "mobile" && (
                <EventDesktop>
                    <Box>
                        <Divider />
                        <Box sx={{ py: 5 }}>
                            <Typography>
                                Descarcă aplicația RestartiX
                            </Typography>
                            <Box sx={{ width: 200, m: "auto" }}>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.restartix.app"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src="/google-play-badge.png"
                                        alt="Descarcă aplicația RestartiX"
                                    />
                                </a>
                            </Box>
                        </Box>
                    </Box>
                </EventDesktop>
            )}
        </PageContainer>
    );
}

export default Event;
